<div class="row m-0">
    <div class="col-12 p-0">
        <div class="row form-contact">
            <div class="col-12">
                <div class="row py-5">
                    <div class="col-12 title-say-hello pt-5">
                        Traducciones
                    </div>
                </div>

            </div>
            <div class="col-12 py-sm-5 box-form-contact">
                <div class="row py-sm-2">
                    <div class="col-md-6 col-12">
                        <input class="form-control" type="text" placeholder="Español" name="es" [(ngModel)]="es">
                        <br>
                        <input class="form-control" type="text" placeholder="Ingles" name="en" [(ngModel)]="en">
                        <br>
                        <div>
                            <select class="form-control" [(ngModel)]="page">
                                <option *ngFor="let option of options" [value]="option">{{option}}</option>
                            </select>
                        </div>
                        <div *ngIf="id != ''">
                            <!-- <input class="form-control" type="text" placeholder="Page" name="page" value="{{viewpage}}" disabled> -->
                        </div>
                    </div>
                    <div class="col-md-6 col-12" style="text-align: center;">
                        <div class="row">
                            <div class="col-6">
                                <button class="btn btn-contact" (click)="addtext()">Agregar</button>
                            </div>
                            <div class="col-6">
                                <button class="btn btn-contact" (click)="clear()">Limpiar</button>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 p-5">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Español</th>
                    <th scope="col">Ingles</th>
                    <th scope="col">Pagina</th>
                    <th scope="col">Editar</th>
                    <th scope="col">Eliminar</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let view of viewtable; index as i">
                    <th scope="row">{{view.es}}</th>
                    <td>{{view.en}}</td>
                    <td>{{view.page == '1'? 'Home' : view.page == 2 ? 'Capacidades' : view.page == 3 ? 'Portafolio' : view.page == 4 ? 'Quienes Somos' : view.page == 5 ? 'Contactanos' : 'Navbar'}}</td>
                    <td><button class="btn btn-success" (click)="edit(view.id, view.es, view.en, view.page)">Editar</button></td>
                    <td><button class="btn btn-danger" (click)="delete(view.id, view.es, view.en, view.page)">Eliminar</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>