import { Component, OnInit, HostListener } from '@angular/core';
import { TranslationsService } from '../translations/translations.service';
import { NavbarComponent } from '../includes/navbar/navbar.component';

@Component({
  selector: 'app-capabilities',
  templateUrl: './capabilities.component.html',
  styleUrls: ['./capabilities.component.css']
})
export class CapabilitiesComponent implements OnInit {

  translations:any = [];
  language:string;
  screenHeight: Number;
  screenWidth: Number;
  loading: boolean = true;

  constructor(
    private translationsService : TranslationsService,
    private navbarComponent : NavbarComponent) {
      this.onResize();
    }

  ngOnInit(): void {
    window.scrollTo(0, 0);

    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }

    this.view();
    setTimeout(() => {
      this.loading = false;
    }, 0);
  }

  scrollServices(el: HTMLElement) {
    el.scrollIntoView({behavior:"smooth"});
    // const id = el.id;
    // const yOffset = -150; 
    // const element = document.getElementById(id);
    // console.log(element);
    // var y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    // if(id == "cx"){
    //   y = 699;
    // }

    // window.scrollTo({top: y, behavior: 'smooth'});
  }

  @HostListener('window:resize', ['$event'])
  onResize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    var barnav = document.getElementById('barnav');
    if(this.screenWidth > 600){
      if (window.scrollY >= 650 ) {
        barnav.classList.add("barnav-fixed");
      } 
      else {
        barnav.classList.remove("barnav-fixed");
      }
    }else{
      if (window.scrollY >= 568 ) {
        barnav.classList.add("barnav-fixed");
      } 
      else {
        barnav.classList.remove("barnav-fixed");
      }

    }
  }

  view(){
    this.translationsService.getone("2").subscribe(
      res => {
        console.log(res);
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }
  // async view(){
  //   this.translations = [];
  //   var answer = await this.translationsService.getone("2");
  //   console.log(answer);
    
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body']; 
  //   }
  // }

}
