<div class="top-welcome">
  <div class="row m-0 base-2">
    <div class="col-12 p-0 max-width-global max-margin-global">
      <div class="banner-welcome-red">
        <div class="title-white">
          <span>{{ translations[12][language.toLowerCase()] }} </span
          >{{ translations[13][language.toLowerCase()] }}
        </div>
        <!-- <div class="title-white">
                    {{translations[0][language.toLowerCase()]}} <span>{{translations[1][language.toLowerCase()]}}</span>
                </div> -->
        <!-- <div class="description-white">
                    <span>{{translations[2][language.toLowerCase()]}}</span>
                </div> -->
      </div>
      <div
        class="arrow-red-down"
        style="z-index: 1"
        (click)="scrollDown(tripled)"
      >
        <img src="./assets/images/arrow-down-red.svg" alt="" />
      </div>
    </div>
  </div>

  <div class="row m-0 container-triple-d" #tripled>
    <div class="col-12 max-width-global max-margin-global">
      <div class="row">
        <div class="col-lg-6 col-12 triple-d text-left">
          <div class="text">
            {{ translations[14][language.toLowerCase()] }}
          </div>
        </div>
        <div class="col-lg-6 col-12 triple-d">
          <div class="text">
            {{ translations[15][language.toLowerCase()] }}
          </div>
        </div>
      </div>
      <br /><br /><br />
      <div class="row">
        <div class="col-12 triple-new" style="z-index: 1">
          <div class="title">
            {{ translations[16][language.toLowerCase()] }}
          </div>
        </div>
      </div>
      <!-- <div class="row">
                <div class="col-lg-6 col-12 triple-d text-left">
                    <div class="text">
                        {{translations[3][language.toLowerCase()]}}
                        <br><br> {{translations[4][language.toLowerCase()]}}
                    </div>
                </div>
                <div class="col-lg-6 col-12 triple-d">
                    <div class="text">
                        {{translations[5][language.toLowerCase()]}}
                    </div>
                </div>
                <div class="col-12 triple-new" style="z-index: 1;">
                    <div class="title">
                        {{translations[6][language.toLowerCase()]}}
                    </div>
                </div>
            </div> -->
    </div>
    <div class="container-mid-circle">
      <img src="./assets/images/vector.svg" alt="" />
    </div>
  </div>

  <div class="container-limits max-width-global max-margin-global">
    <div class="row m-0">
      <div class="col-md-6 col-12 p-0">
        <div class="w-100 container-limits-intro">
          <div class="description">
            {{ translations[17][language.toLowerCase()] }}
          </div>
          <!-- <div class="description">{{translations[7][language.toLowerCase()]}}</div> -->
          <!-- <div class="uppercase-text-blue">{{translations[8][language.toLowerCase()]}}</div> -->
        </div>
      </div>
      <div class="col-md-6 col-12 p-0 container-img-no-limits">
        <img
          src="./assets/images/sin-limites.svg"
          alt=""
          style="margin-top: 30px"
        />
        <div class="container-btn-pf-blue">
          <button class="btn btn-pf-blue">
            {{ translations[9][language.toLowerCase()] }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="container-certif">
    <div class="row m-0 max-width-global max-margin-global">
      <div class="col-12 p-0">
        <div class="title-wow">
          {{ translations[10][language.toLowerCase()] }} <br />
          <span>{{ translations[11][language.toLowerCase()] }}</span>
        </div>
      </div>
    </div>

    <div class="row m-0 max-width-global max-margin-global">
      <div class="col-12 p-0">
        <div class="row m-0">
          <div
            class="col-lg-6 col-12 p-0"
            *ngFor="let certificates of array_certificates"
          >
            <div class="text-wow">
              <p>{{ certificates.name }}</p>
            </div>
            <div class="img-wow">
              <img [src]="certificates.url_logo" [alt]="certificates.name" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row m-0 max-width-global max-margin-global">
      <div class="col-12 p-0">
        <div class="title-wow">
          <br />
          <span>PARTNERS</span>
        </div>
      </div>
    </div>

    <div class="row m-0 max-width-global max-margin-global">
      <div class="col-12 p-0">
        <div class="row m-0">
          <div
            class="col-lg-4 col-12 p-0"
            *ngFor="let partner of array_partners"
          >
            <div class="text-wow">
              <p>{{ partner.name }}</p>
            </div>
            <div class="img-wow">
              <a [href]="partner.href" target="_blank">
                <img [src]="partner.url_logo" [alt]="partner.name" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="row m-0">
    <div class="col-12 d-none d-lg-block p-0">
      <img
        style="width: inherit"
        src="./assets/icons/certificates/backimage-certificates.svg"
      />
    </div>
    <div class="col-12 d-md-none d-block p-0">
      <img
        style="width: inherit"
        src="./assets/icons/certificates/backimage-certificates-mob.svg"
      />
    </div>
    <div class="col-12 d-md-block d-none d-lg-none p-0">
      <img
        style="width: inherit"
        src="./assets/icons/certificates/backimage-certificates-tablet.svg"
      />
    </div>
  </div>
</div>

<div #contactus class="row m-0">
  <div class="col-12 p-0" style="max-width: 1280px; margin: 0 auto">
    <app-contactus></app-contactus>
  </div>
</div>
