<div class="top-services">
  <div class="row base-1 m-0">
    <div class="col-12 p-0">
      <div class="row base-2 m-0" id="initial-block">
        <div class="col-12" style="max-width: 1280px; margin: 0 auto">
          <div class="row">
            <div class="col-12 p-0">
              <div class="card banner-services">
                <div class="title" style="z-index: 1">
                  {{ translations[145][language.toLowerCase()] }}
                </div>
                <!-- <div class="title" style="z-index: 1;">
                                    {{translations[0][language.toLowerCase()]}}
                                </div> -->
                <div class="description" style="z-index: 1">
                  {{ translations[1][language.toLowerCase()] }}
                </div>
                <div
                  style="
                    align-self: center;
                    margin-top: 12px;
                    z-index: 1;
                    cursor: pointer;
                  "
                  (click)="scrollServices(cx)"
                >
                  <img src="./assets/icons/arrow-down-red.svg" alt="" />
                </div>
                <div class="tabs-services" style="z-index: 1">
                  <ul
                    ngbNav
                    #nav="ngbNav"
                    class="nav-tabs border-0"
                    style="justify-content: center"
                  >
                    <li ngbNavItem>
                      <a ngbNavLink (click)="scrollServices(cx)">{{
                        translations[2][language.toLowerCase()]
                      }}</a>
                      <ng-template ngbNavContent>
                        <div class="subtitle-navs">
                          {{ translations[2][language.toLowerCase()] }}
                        </div>
                      </ng-template>
                    </li>
                    <li ngbNavItem>
                      <a ngbNavLink (click)="scrollServices(ux)">{{
                        translations[3][language.toLowerCase()]
                      }}</a>
                      <ng-template ngbNavContent>
                        <div class="subtitle-navs">
                          {{ translations[3][language.toLowerCase()] }}
                        </div>
                      </ng-template>
                    </li>
                    <li ngbNavItem>
                      <a ngbNavLink (click)="scrollServices(td)">{{
                        translations[4][language.toLowerCase()]
                      }}</a>
                      <ng-template ngbNavContent>
                        <div class="subtitle-navs">
                          {{ translations[4][language.toLowerCase()] }}
                        </div>
                      </ng-template>
                    </li>
                    <li ngbNavItem>
                      <a ngbNavLink (click)="scrollServices(amt)">{{
                        translations[144][language.toLowerCase()]
                      }}</a>
                      <ng-template ngbNavContent>
                        <div class="subtitle-navs">
                          {{ translations[144][language.toLowerCase()] }}
                        </div>
                      </ng-template>
                    </li>
                    <li ngbNavItem>
                      <a ngbNavLink (click)="scrollServices(pl)">{{
                        translations[148][language.toLowerCase()]
                      }}</a>
                      <ng-template ngbNavContent>
                        <div class="subtitle-navs">
                          {{ translations[148][language.toLowerCase()] }}
                        </div>
                      </ng-template>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div
              class="p-0 d-none d-md-block"
              style="position: absolute; height: -webkit-fill-available"
            >
              <img
                style="position: absolute; bottom: 0; left: 0"
                src="./assets/images/mid-circle-red.svg"
                alt=""
              />
              <div class="geometric-form-welcome-black"></div>
            </div>
            <div
              class="d-block d-md-none container-geometrict-form-mb"
              style="position: absolute; height: inherit"
            >
              <img
                style="right: 0; bottom: 0; position: absolute"
                src="./assets/images/img-services-mb.svg"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
      <div class="w-100" style="background: #ffffff" id="barnav">
        <div style="max-width: 1280px; margin: 0 auto">
          <ul
            ngbNav
            #nav="ngbNav"
            class="d-none d-lg-flex nav-tabs tabs-secondaries-services"
            style="justify-content: center"
          >
            <li ngbNavItem>
              <a ngbNavLink (click)="scrollServices(cx)">
                <span>{{ translations[6][language.toLowerCase()] }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="subtitle-navs">
                  {{ translations[6][language.toLowerCase()] }}
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink (click)="scrollServices(ux)">
                <span>{{ translations[7][language.toLowerCase()] }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="subtitle-navs">
                  {{ translations[7][language.toLowerCase()] }}
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink (click)="scrollServices(td)">
                <span>{{ translations[142][language.toLowerCase()] }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="subtitle-navs">
                  {{ translations[142][language.toLowerCase()] }}
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink (click)="scrollServices(amt)">
                <span>{{ translations[143][language.toLowerCase()] }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="subtitle-navs">
                  {{ translations[143][language.toLowerCase()] }}
                </div>
              </ng-template>
            </li>
            <li ngbNavItem>
              <a ngbNavLink (click)="scrollServices(pl)">
                <span>{{ translations[147][language.toLowerCase()] }}</span>
              </a>
              <ng-template ngbNavContent>
                <div class="subtitle-navs">
                  {{ translations[147][language.toLowerCase()] }}
                </div>
              </ng-template>
            </li>
          </ul>
        </div>
        <ul
          ngbNav
          #nav="ngbNav"
          class="d-flex d-lg-none nav-tabs tabs-secondaries-services"
          style="justify-content: center"
        >
          <li ngbNavItem>
            <a ngbNavLink (click)="scrollServices(cx)">{{
              translations[2][language.toLowerCase()]
            }}</a>
            <ng-template ngbNavContent>
              <div class="subtitle-navs">
                {{ translations[2][language.toLowerCase()] }}
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink (click)="scrollServices(ux)">{{
              translations[3][language.toLowerCase()]
            }}</a>
            <ng-template ngbNavContent>
              <div class="subtitle-navs">
                {{ translations[3][language.toLowerCase()] }}
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink (click)="scrollServices(td)">{{
              translations[4][language.toLowerCase()]
            }}</a>
            <ng-template ngbNavContent>
              <div class="subtitle-navs">
                {{ translations[4][language.toLowerCase()] }}
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink (click)="scrollServices(amt)">{{
              translations[144][language.toLowerCase()]
            }}</a>
            <ng-template ngbNavContent>
              <div class="subtitle-navs">
                {{ translations[144][language.toLowerCase()] }}
              </div>
            </ng-template>
          </li>
          <li ngbNavItem>
            <a ngbNavLink (click)="scrollServices(pl)">{{
              translations[148][language.toLowerCase()]
            }}</a>
            <ng-template ngbNavContent>
              <div class="subtitle-navs">
                {{ translations[148][language.toLowerCase()] }}
              </div>
            </ng-template>
          </li>
        </ul>
      </div>
      <div class="container-cx" #cx id="cx">
        <div style="max-width: 1280px; margin: 0 auto">
          <div class="w-100 container-cx-intro">
            <div class="subtitle">
              {{ translations[2][language.toLowerCase()] }}
            </div>
            <div class="title">
              {{ translations[10][language.toLowerCase()] }}
              <span>{{ translations[11][language.toLowerCase()] }}</span>
            </div>
            <div class="description">
              {{ translations[135][language.toLowerCase()] }} <br /><br />
              {{ translations[136][language.toLowerCase()] }}
            </div>
            <div class="uppercase-text-blue">
              {{ translations[137][language.toLowerCase()] }}
              {{ translations[138][language.toLowerCase()] }}
            </div>
            <!-- <div class="uppercase-text-red">{{translations[138][language.toLowerCase()]}}</div> -->
          </div>
        </div>
        <div class="container-cards-black">
          <div class="row mb-md-5" style="max-width: 1280px; margin: 0 auto">
            <div class="col-lg-6 col-12 card-black-cx">
              <div class="row">
                <div class="col-md-6 col-12 d-md-none d-block img-card-black">
                  <img src="./assets/images/figure-cx-1.svg" alt="" />
                </div>
                <div class="col-md-6 col-12">
                  <div class="subtitle">
                    {{ translations[16][language.toLowerCase()] }}
                  </div>
                  <div class="title">
                    {{ translations[139][language.toLowerCase()] }}
                  </div>
                </div>
                <div class="col-md-6 col-12 d-md-block d-none img-card-black">
                  <img src="./assets/images/figure-cx-1.svg" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 last-text">
                  {{ translations[18][language.toLowerCase()] }} &#x25cf;
                  {{ translations[19][language.toLowerCase()] }} &#x25cf;
                  {{ translations[20][language.toLowerCase()] }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 card-black-cx">
              <div class="row">
                <div class="col-md-6 col-12 d-md-none d-block img-card-black">
                  <img src="./assets/images/figure-cx-2.svg" alt="" />
                </div>
                <div class="col-md-6 col-12">
                  <div class="subtitle">
                    {{ translations[21][language.toLowerCase()] }}
                  </div>
                  <div class="title">
                    {{ translations[22][language.toLowerCase()] }}
                  </div>
                </div>
                <div class="col-md-6 col-12 d-md-block d-none img-card-black">
                  <img src="./assets/images/figure-cx-2.svg" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 last-text">
                  {{ translations[23][language.toLowerCase()] }} &#x25cf;
                  {{ translations[24][language.toLowerCase()] }}
                  <br />
                  &#x25cf;
                  {{ translations[25][language.toLowerCase()] }} &#x25cf;
                  {{ translations[26][language.toLowerCase()] }}
                </div>
              </div>
            </div>
          </div>
          <div class="row" style="max-width: 1280px; margin: 0 auto">
            <div class="col-lg-6 col-12 card-black-cx">
              <div class="row">
                <div class="col-md-6 col-12 d-md-none d-block img-card-black">
                  <img src="./assets/images/figure-cx-3.svg" alt="" />
                </div>
                <div class="col-md-6 col-12">
                  <div class="subtitle">
                    {{ translations[27][language.toLowerCase()] }}
                  </div>
                  <div class="title">
                    {{ translations[28][language.toLowerCase()] }}
                  </div>
                </div>
                <div class="col-md-6 col-12 d-md-block d-none img-card-black">
                  <img src="./assets/images/figure-cx-3.svg" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 last-text">
                  {{ translations[29][language.toLowerCase()] }}<br />
                  &#x25cf;
                  {{ translations[30][language.toLowerCase()] }} &#x25cf;
                  {{ translations[31][language.toLowerCase()] }} &#x25cf;
                  {{ translations[32][language.toLowerCase()] }} &#x25cf;
                  {{ translations[33][language.toLowerCase()] }}
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-12 card-black-cx">
              <div class="row">
                <div class="col-md-6 col-12 d-md-none d-block img-card-black">
                  <img src="./assets/images/figure-cx-4.svg" alt="" />
                </div>
                <div class="col-md-6 col-12">
                  <div class="subtitle">
                    {{ translations[34][language.toLowerCase()] }}
                  </div>
                  <div class="title">
                    {{ translations[35][language.toLowerCase()] }}
                  </div>
                </div>
                <div class="col-md-6 col-12 d-md-block d-none img-card-black">
                  <img src="./assets/images/figure-cx-4.svg" alt="" />
                </div>
              </div>
              <div class="row">
                <div class="col-12 last-text">
                  {{ translations[36][language.toLowerCase()] }} &#x25cf;
                  {{ translations[37][language.toLowerCase()] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          class="row container-dif-cx-ux"
          style="max-width: 1280px; margin: 40px auto"
        >
          <div class="col-lg-5 col-md-6 col-12 p-0">
            <div class="bg-red">
              {{ translations[140][language.toLowerCase()] }}
              <!-- {{translations[38][language.toLowerCase()]}} <span>{{translations[39][language.toLowerCase()]}}</span> {{translations[40][language.toLowerCase()]}} -->
            </div>
          </div>
          <!-- <div class="col-lg-7 col-md-6 col-12 p-0" style="margin-top: 26px;"> -->
          <div class="col-lg-7 col-md-6 col-12 p-0">
            <!-- <div class="title">
                            {{translations[41][language.toLowerCase()]}}
                        </div> -->
            <br />
            <div class="description">
              {{ translations[141][language.toLowerCase()] }}
            </div>
          </div>
        </div>
        <div class="w-100 container-footer-cx d-md-flex">
          <div class="img-footer-cx" style="display: grid">
            <img src="./assets/images/figure-cx-5.svg" alt="" />
            <img src="./assets/images/figure-cx-6.svg" alt="" />
          </div>
          <div class="row m-0 container-text">
            <div class="col-12 title">
              {{ translations[43][language.toLowerCase()] }}
            </div>
            <div class="col-12 description">
              <!-- <b (click)="scrollServices(contactus)">{{translations[44][language.toLowerCase()]}}</b> {{translations[45][language.toLowerCase()]}} -->
              {{ translations[132][language.toLowerCase()] }}
              <b (click)="scrollServices(contactus)">{{
                translations[133][language.toLowerCase()]
              }}</b>
              {{ translations[134][language.toLowerCase()] }}
            </div>
          </div>
        </div>
      </div>
      <div class="container-ux" #ux id="ux">
        <div class="row m-0 container-ux-intro">
          <div class="col-12" style="max-width: 1280px; margin: 0 auto">
            <div class="row">
              <div class="col-lg-7 col-12 p-0">
                <div class="subtitle">
                  {{ translations[3][language.toLowerCase()] }}
                </div>
                <div class="title">
                  {{ translations[46][language.toLowerCase()] }}
                  <span>{{ translations[47][language.toLowerCase()] }}</span>
                </div>
                <div class="description">
                  {{ translations[48][language.toLowerCase()] }}
                  <b>{{ translations[49][language.toLowerCase()] }}</b>
                  {{ translations[50][language.toLowerCase()] }}
                  {{ translations[128][language.toLowerCase()] }}
                </div>
              </div>
              <div class="col-lg-5 col-12 p-0 container-img-ux">
                <img src="./assets/images/figure-ux-1.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 container-black-info">
          <div class="block-blue"></div>
          <div
            class="col-12 p-0 d-lg-flex max-width-global max-margin-global"
            style="max-width: 1110px"
          >
            <div class="text-red">
              {{ translations[51][language.toLowerCase()] }}
            </div>
            <div class="arrows">
              <div class="d-flex">
                <img src="./assets/images/arrow.svg" alt="" />
                <span>{{ translations[52][language.toLowerCase()] }}</span>
              </div>
              <div class="d-flex">
                <img src="./assets/images/arrow.svg" alt="" />
                <span>{{ translations[53][language.toLowerCase()] }}</span>
              </div>
              <div class="d-flex">
                <img src="./assets/images/arrow.svg" alt="" />
                <span>{{ translations[54][language.toLowerCase()] }}</span>
              </div>
              <div class="sphere-red"></div>
            </div>
          </div>
        </div>
        <div class="container-ux-description">
          <div class="circle-background">
            <img src="./assets/images/Ellipse 56.svg" alt="" />
          </div>
          <div class="row" style="max-width: 1280px; margin: 0 auto">
            <div class="col-6 d-none d-lg-block p-0"></div>
            <div class="col-lg-6 col-12 p-0">
              <!-- <div class="text-blue principal-text">{{translations[55][language.toLowerCase()]}}</div> -->
              <div class="text-blue principal-text">
                {{ translations[130][language.toLowerCase()] }}
              </div>
            </div>
          </div>
          <div class="row" style="max-width: 1280px; margin: 0 auto">
            <div class="col-md-6 col-12 p-0">
              <!-- <div class="title-red">{{translations[56][language.toLowerCase()]}}</div> -->
              <div class="title-red">
                {{ translations[129][language.toLowerCase()] }}
              </div>
            </div>
            <div class="col-md-6 col-12 p-0">
              <ul class="list-ux">
                <li class="item-ux">
                  <span>{{ translations[57][language.toLowerCase()] }}</span>
                </li>
                <li class="item-ux">
                  <span>{{ translations[58][language.toLowerCase()] }}</span>
                </li>
                <li class="item-ux">
                  <span>{{ translations[59][language.toLowerCase()] }}</span>
                </li>
                <li class="item-ux">
                  <span>{{ translations[60][language.toLowerCase()] }}</span>
                </li>
                <li class="item-ux">
                  <span>{{ translations[61][language.toLowerCase()] }}</span>
                </li>
                <li class="item-ux">
                  <span>{{ translations[62][language.toLowerCase()] }}</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row" style="max-width: 1280px; margin: 0 auto">
            <div class="col-md-6 col-12 p-0">
              <!-- <div class="title-red" style="text-align: left;">{{translations[63][language.toLowerCase()]}}</div> -->
            </div>
            <div class="col-md-6 col-12 p-0">
              <div class="text-blue">
                {{ translations[64][language.toLowerCase()] }}
              </div>
            </div>
          </div>
          <div class="row m-0">
            <div
              class="col-lg-7 col-12 p-0"
              style="max-width: 878px; margin-left: auto"
            >
              <!-- <div class="text-description">{{translations[65][language.toLowerCase()]}}</div> -->
              <div class="text-description">
                {{ translations[131][language.toLowerCase()] }}
              </div>
            </div>
            <div class="col-lg-5 col-12 p-0">
              <div class="img-ux">
                <img src="./assets/images/img-ux.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="w-100 container-footer-ux d-md-flex">
          <div class="img-footer-cx" style="display: grid">
            <img src="./assets/images/figure-cx-5.svg" alt="" />
            <img src="./assets/images/figure-cx-6.svg" alt="" />
          </div>
          <div
            class="row m-0 container-text max-width-global max-margin-global"
          >
            <div class="col-12 title">
              {{ translations[66][language.toLowerCase()] }}
            </div>
            <div class="col-12 description">
              <!-- <b (click)="scrollServices(contactus)">{{translations[67][language.toLowerCase()]}}</b> {{translations[68][language.toLowerCase()]}} -->
              {{ translations[132][language.toLowerCase()] }}
              <b (click)="scrollServices(contactus)">{{
                translations[133][language.toLowerCase()]
              }}</b>
              {{ translations[134][language.toLowerCase()] }}
            </div>
          </div>
        </div>
      </div>
      <div class="container-td" #td id="td">
        <div class="row m-0 container-td-intro">
          <div class="col-12 p-0">
            <!-- <div class="title max-width-global max-margin-global">{{translations[69][language.toLowerCase()]}} <span>{{translations[70][language.toLowerCase()]}}</span></div> -->
            <div class="title max-width-global max-margin-global">
              {{ translations[142][language.toLowerCase()] }}
            </div>
          </div>
          <div class="col-12 p-0">
            <div class="row m-0">
              <div class="col-lg-5 col-12 p-0 d-md-none d-block">
                <div class="img-intro-td">
                  <img src="./assets/images/img-td.jpg" alt="" />
                </div>
              </div>
              <div
                class="col-lg-7 col-12 p-0"
                style="margin-left: auto; max-width: 1080px"
              >
                <div class="title-blue">
                  <!-- {{translations[71][language.toLowerCase()]}} -->
                </div>
                <div class="description">
                  {{ translations[72][language.toLowerCase()] }}
                  <b>{{ translations[73][language.toLowerCase()] }}</b>
                  {{ translations[74][language.toLowerCase()] }}
                </div>
              </div>
              <div class="col-lg-5 col-12 p-0 d-md-block d-none">
                <div class="img-intro-td">
                  <img src="./assets/images/img-td.jpg" alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 container-blue-td">
          <div class="col-lg-5 col-12 p-0 block-black-red">
            <div class="semisphere-black">
              <img src="./assets/images/vector.svg" alt="" />
            </div>
          </div>
          <div class="col-lg-7 col-12 p-0" style="align-self: center">
            <div class="title">
              {{ translations[75][language.toLowerCase()] }}
            </div>
            <div class="list-td">
              <ul>
                <li class="item-td">
                  {{ translations[76][language.toLowerCase()] }}
                </li>
                <li class="item-td">
                  {{ translations[77][language.toLowerCase()] }}
                </li>
                <li class="item-td">
                  {{ translations[78][language.toLowerCase()] }}
                </li>
                <li class="item-td">
                  {{ translations[79][language.toLowerCase()] }}
                </li>
                <li class="item-td">
                  {{ translations[80][language.toLowerCase()] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="row m-0 container-td-help max-width-global max-margin-global"
        >
          <div class="col-md-6 col-12 p-0">
            <div class="title-red">
              {{ translations[81][language.toLowerCase()] }}
            </div>
          </div>
          <div class="col-md-6 col-12 p-0">
            <div class="list-help">
              <ul style="padding: 0">
                <li class="item-help-td">
                  {{ translations[82][language.toLowerCase()] }}
                </li>
                <li class="item-help-td">
                  {{ translations[83][language.toLowerCase()] }}
                </li>
                <li class="item-help-td">
                  {{ translations[84][language.toLowerCase()] }}
                </li>
                <li class="item-help-td">
                  {{ translations[85][language.toLowerCase()] }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="row m-0 container-td-black">
          <div class="col-12 p-0 d-md-flex">
            <div class="text-plus max-width-global">
              {{ translations[86][language.toLowerCase()] }}
              <span>{{ translations[87][language.toLowerCase()] }}</span
              >.
            </div>
            <div class="img-lines">
              <img src="./assets/images/lines-blue-black.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
      <div class="container-amt" #amt id="amt">
        <div class="row m-0 container-amt-intro">
          <div class="col-12 p-0">
            <div class="title max-width-global max-margin-global">
              {{ translations[143][language.toLowerCase()] }}
            </div>
          </div>
        </div>
        <div class="row m-0 container-rpa" style="padding-bottom: 0px">
          <div class="col-12">
            <div class="title-blue">
              {{ translations[149][language.toLowerCase()] }}
            </div>
            <div class="description-mineria" style="max-width: fit-content">
              {{ translations[150][language.toLowerCase()] }}
            </div>
          </div>
        </div>
        <div
          class="row m-0 container-rpa-tomorrow"
          style="padding-top: 0px; padding-bottom: 0px"
        >
          <div class="col-lg-6 col-12 p-0">
            <div class="img-rpa-mineria">
              <img src="./assets/images/Bitmap Copy.png" alt="" />
            </div>
          </div>
          <div class="col-lg-6 col-12 p-0 pl-4 description-list">
            <ul style="padding: 0">
              <li
                style="
                  background: url(../../../assets/images/icon-list-ux.svg)
                    no-repeat 0px 16px transparent;
                  list-style-type: none;
                  margin: 0;
                  padding: 0px 0px 1px 45px;
                "
              >
                {{ translations[151][language.toLowerCase()] }}
                <b style="font-family: 'acrom-bold'">{{
                  translations[152][language.toLowerCase()]
                }}</b>
                {{ translations[153][language.toLowerCase()] }}
              </li>
              <li
                style="
                  background: url(../../../assets/images/icon-list-ux.svg)
                    no-repeat 0px 16px transparent;
                  list-style-type: none;
                  margin: 0;
                  padding: 0px 0px 1px 45px;
                "
              >
                {{ translations[154][language.toLowerCase()] }}
                <b style="font-family: 'acrom-bold'">{{
                  translations[155][language.toLowerCase()]
                }}</b>
              </li>
              <li
                style="
                  background: url(../../../assets/images/icon-list-ux.svg)
                    no-repeat 0px 16px transparent;
                  list-style-type: none;
                  margin: 0;
                  padding: 0px 0px 1px 45px;
                "
              >
                {{ translations[156][language.toLowerCase()] }}
                <b style="font-family: 'acrom-bold'">{{
                  translations[157][language.toLowerCase()]
                }}</b>
                {{ translations[158][language.toLowerCase()] }}
              </li>
              <li
                style="
                  background: url(../../../assets/images/icon-list-ux.svg)
                    no-repeat 0px 16px transparent;
                  list-style-type: none;
                  margin: 0;
                  padding: 0px 0px 1px 45px;
                "
              >
                {{ translations[159][language.toLowerCase()] }}
                <b style="font-family: 'acrom-bold'">{{
                  translations[160][language.toLowerCase()]
                }}</b>
                {{ translations[161][language.toLowerCase()] }}
              </li>
            </ul>
          </div>
        </div>
        <!-- <div class="row m-0 container-rpa" style="padding-bottom:0px;padding-top:0px;">
                    <div class="col-12">
                        <div class="description-mineria" style="max-width: fit-content;">{{translations[162][language.toLowerCase()]}}</div>
                        <div class="description-mineria" style="max-width: fit-content;">{{translations[163][language.toLowerCase()]}}</div>
                    </div>
                </div> -->
        <div class="row m-0 container-rpa">
          <div class="col-12">
            <div class="row max-width-global max-margin-global">
              <div class="col-lg-7 col-12 p-0">
                <div class="title-blue">
                  {{ translations[88][language.toLowerCase()] }}
                </div>
                <div class="description">
                  {{ translations[89][language.toLowerCase()] }}
                  <b>{{ translations[90][language.toLowerCase()] }}</b>
                  {{ translations[91][language.toLowerCase()] }}
                  <b>{{ translations[92][language.toLowerCase()] }}</b>
                  {{ translations[93][language.toLowerCase()] }}
                </div>
                <div class="title-red d-lg-block d-none">
                  {{ translations[94][language.toLowerCase()] }}
                </div>
              </div>
              <div class="col-lg-5 col-12 p-0">
                <div class="img-rpa">
                  <img src="./assets/images/image-rpa.png" alt="" />
                </div>
              </div>
              <div class="col-12 p-0">
                <div class="title-red d-lg-none d-block">
                  {{ translations[94][language.toLowerCase()] }}
                </div>
                <div class="list-rpa">
                  <ul style="padding: 0">
                    <li class="item-list-rpa">
                      <span>{{
                        translations[95][language.toLowerCase()]
                      }}</span>
                      - {{ translations[96][language.toLowerCase()] }}
                    </li>
                    <li class="item-list-rpa">
                      <span>{{
                        translations[97][language.toLowerCase()]
                      }}</span>
                      - {{ translations[98][language.toLowerCase()] }}
                    </li>
                    <li class="item-list-rpa">
                      <span>{{
                        translations[99][language.toLowerCase()]
                      }}</span>
                      - {{ translations[100][language.toLowerCase()] }}
                    </li>
                    <li class="item-list-rpa">
                      <span>{{
                        translations[101][language.toLowerCase()]
                      }}</span>
                      - {{ translations[102][language.toLowerCase()] }}
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-6 col-12 p-0">
                <div class="description-blue">
                  {{ translations[103][language.toLowerCase()] }}
                </div>
              </div>
              <div class="col-lg-6 col-12 p-0">
                <div class="description-list">
                  <ul style="padding: 0">
                    <li
                      style="
                        background: url(../../../assets/images/icon-list-ux.svg)
                          no-repeat 0px 16px transparent;
                        list-style-type: none;
                        margin: 0;
                        padding: 0px 0px 1px 45px;
                      "
                    >
                      {{ translations[104][language.toLowerCase()] }}
                    </li>
                    <!-- <li style="background: url(../../../assets/images/icon-list-ux.svg) no-repeat 0px 16px transparent;list-style-type: none;margin: 0;padding: 0px 0px 1px 45px;">
                                            {{translations[105][language.toLowerCase()]}}
                                        </li> -->
                    <li
                      style="
                        background: url(../../../assets/images/icon-list-ux.svg)
                          no-repeat 0px 16px transparent;
                        list-style-type: none;
                        margin: 0;
                        padding: 0px 0px 1px 45px;
                      "
                    >
                      {{ translations[106][language.toLowerCase()] }}
                    </li>
                    <li
                      style="
                        background: url(../../../assets/images/icon-list-ux.svg)
                          no-repeat 0px 16px transparent;
                        list-style-type: none;
                        margin: 0;
                        padding: 0px 0px 1px 45px;
                      "
                    >
                      {{ translations[107][language.toLowerCase()] }}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0">
          <div class="col-12 p-0">
            <div class="container-black-bg d-flex">
              <div class="container-red-bg">
                <div class="block-black"></div>
              </div>
              <div class="title-rpa">
                <div class="title-red">
                  {{ translations[108][language.toLowerCase()] }}
                </div>
                <div class="title-white">
                  {{ translations[109][language.toLowerCase()] }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row m-0 container-rpa-tomorrow">
          <div class="col-md-5 col-12 p-0">
            <div class="img-rpa-tomorrow">
              <img src="./assets/images/image-rpa-robot.png" alt="" />
            </div>
          </div>
          <div class="col-md-7 col-12 p-0 text-rpa-tomorrow">
            <div class="title-blue">
              {{ translations[110][language.toLowerCase()] }}
            </div>
            <div class="description">
              {{ translations[111][language.toLowerCase()] }}
            </div>
          </div>
        </div>
        <div class="w-100 container-footer-rpa d-flex">
          <div class="img-footer-rpa">
            <img
              style="float: left"
              src="./assets/images/figure-cx-5.svg"
              alt=""
            />
            <img
              style="float: left"
              src="./assets/images/figure-cx-6.svg"
              alt=""
            />
          </div>
          <div class="row m-0 container-text">
            <div class="col-12 title">
              {{ translations[112][language.toLowerCase()] }}
            </div>
            <div class="col-12 description">
              <!-- <b (click)="scrollServices(contactus)">{{translations[113][language.toLowerCase()]}}</b> {{translations[114][language.toLowerCase()]}} -->
              {{ translations[132][language.toLowerCase()] }}
              <b (click)="scrollServices(contactus)">{{
                translations[133][language.toLowerCase()]
              }}</b>
              {{ translations[134][language.toLowerCase()] }}
            </div>
          </div>
        </div>
      </div>
      <div class="row m-0 container-playful-lab" #pl id="pl">
        <div class="col-12 p-0 titles max-width-global">
          <div class="subtitle">
            {{ translations[147][language.toLowerCase()] }}
          </div>
          <div class="title">
            {{ translations[116][language.toLowerCase()] }}
            <span>{{ translations[117][language.toLowerCase()] }}</span>
            {{ translations[118][language.toLowerCase()] }}
          </div>
        </div>
        <div class="col-lg-7 col-12 p-0">
          <div class="description">
            {{ translations[119][language.toLowerCase()] }}
          </div>
          <div class="row m-0 block-black">
            <div class="col-lg-8 col-12 p-0" style="z-index: 1">
              <div class="title-red">
                {{ translations[122][language.toLowerCase()] }}
              </div>
              <div class="list-item">
                <ul style="padding: 0">
                  <li class="items">
                    {{ translations[123][language.toLowerCase()] }}
                  </li>
                  <li class="items">
                    {{ translations[124][language.toLowerCase()] }}
                  </li>
                  <li class="items">
                    {{ translations[125][language.toLowerCase()] }}
                  </li>
                </ul>
              </div>
            </div>
            <div class="col-lg-4 col-12 p-0 container-block-blue">
              <img src="./assets/images/mid-circle-blue.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="col-lg-5 col-12 p-0 container-last-text-blue">
          <div class="title-blue">
            {{ translations[120][language.toLowerCase()] }}
          </div>
          <div class="text">
            {{ translations[121][language.toLowerCase()] }}
            <br /><br />
            {{ translations[126][language.toLowerCase()] }}
          </div>
        </div>
        <div class="col-12 p-0 max-width-global max-margin-global">
          <div class="advice-blue">
            {{ translations[164][language.toLowerCase()] }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div #contactus class="row m-0">
  <div class="col-12 p-0" style="max-width: 1280px; margin: 0 auto">
    <app-contactus></app-contactus>
  </div>
</div>
