import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { TranslationsService } from '../translations/translations.service';
import { NavbarComponent } from './../includes/navbar/navbar.component';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
  translations:any = [];
  language:string;
  loading: boolean = true;

  constructor(
    private translationsService : TranslationsService,
    private navbarComponent : NavbarComponent,
    private meta_description: Meta) {
  }

  
  
  ngOnInit() {
    
    var language = navigator.language;
    var content_description = "";
    
    if(language.includes("es")){
      content_description = `Consultora de Innovación de Negocios. 
      Diseñamos soluciones estratégicas para optimizar tus servicios y productos digitales.`
    }else{
      content_description = `Business Innovation Consulting. 
      We design strategic solutions to optimize your digital services and products.`
    }

    this.meta_description.addTag({name: "description", content: content_description})
    this.meta_description.addTag({name: "keywords", content: "Playful playful UX RPA innovation"})

    window.scrollTo(0, 0);    
    if(localStorage.getItem("lang") != null) {
      this.language = localStorage.getItem("lang");
    }else{
      this.language = this.navbarComponent.langSelected;
    }
    this.view();
    setTimeout(() => {
      this.loading = false;
    }, 0);
  }

  scrollToForm(el: HTMLElement){
    el.scrollIntoView({behavior:"smooth"});
  }

  visitPlayfulHub (){
    var newTab = window.open("https://expotourvirtual.com/playfulo2c/", "_blank");
    newTab.location;
  }

  view(){
    this.translationsService.getone("1").subscribe(
      res => {
        this.translations = res['body'];
        console.log('Translations array:', this.translations);
        console.log('Translation 52:', this.translations[52]);
        console.log('Translation 32:', this.translations[32]);
        console.log('Translation 33:', this.translations[33]);
        console.log(res);
      },
      err =>{
        console.log(err);
      }
    )
  }

  // async view(){
  //   this.translations = [];
  //   var answer =  await this.translationsService.getone("1");
  //   console.log(answer);
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body'];
  //   }
  // }
}
