<div class="row form-contact">
    <div class="col-lg-6 col-12">
        <div class="row py-5">
            <div class="col-12 title-say-hello">
                {{ translations[12][language.toLowerCase()] }} <span>{{ translations[13][language.toLowerCase()] }}</span>
            </div>
        </div>
        <div class="row py-5 container-address-pf">
            <div class="col-6 py-1">
                <!-- <div class="row my-2">
                    <div class="col-12 subtitle-say-hello">
                        MONTERREY, N.L HQ
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-12 text-say-hello">
                        Av. Revolución No. 643 Local 12 <br>Col. Jardín Español, Monterrey, <br>Nuevo León <br>CP.: 64820
                    </div>
                </div> -->
                <div class="row my-2">
                    <div class="col-12">
                        <br>
                        <div class="text-say-hello"><a href="tel:+528181040389">t. +52 81 8104 0389
                        </a></div>
                    </div>
                    <div class="col-12">
                        <br>
                        <div class="text-say-hello"><a href="mailto:contacto@playful.mx">contacto@playful.mx</a></div>
                    </div>
                </div>
            </div>
            <div class="col-6 py-1">
                <!-- <div class="row my-2">
                    <div class="col-12 subtitle-say-hello">
                        CDMX
                    </div>
                </div>
                <div class="row my-2">
                    <div class="col-12 text-say-hello">
                        Coahuila 14 int. 303 <br>Col. Roma Norte <br>Ciudad de México <br>CP.: 04380
                    </div>
                </div> -->
                <!-- <div class="row my-2">
                    <div class="col-12">
                        <br>
                        <div class="tlf-say-hello"><a href="mailto:contacto@playful.mx">contacto@playful.mx</a></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
    <div class="col-lg-6 col-12 py-sm-5 box-form-contact">
        <div class="row py-sm-2">
            <div class="col-md-6 col-12">
                <input class="form-control" [ngClass]="nameflag ? 'ng-invalid' : ''" type="text" placeholder="{{ translations[1][language.toLowerCase()] }}" name="nombre" [(ngModel)]="name">
                <p class="error-p" *ngIf="nameflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
            <div class="col-md-6 col-12">
                <input class="form-control" [ngClass]="lastnameflag ? 'ng-invalid' : ''" type="text" placeholder="{{ translations[2][language.toLowerCase()] }}" name="apellido" [(ngModel)]="lastname">
                <p class="error-p" *ngIf="lastnameflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
        </div>
        <div class="row py-sm-2">
            <div class="col-md-6 col-12">
                <input class="form-control" [ngClass]="emailflag ? 'ng-invalid' : ''" type="email" placeholder="{{ translations[9][language.toLowerCase()] }}" name="email" [(ngModel)]="email">
                <p class="error-p" *ngIf="emailflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
            <div class="col-md-6 col-12 contact-us">
                <select class="form-control" [ngClass]="locationsflag ? 'ng-invalid' : ''" name="locations" [(ngModel)]="locations">
                    <option hidden [ngValue]="null" [disabled]="true" selected>{{ translations[7][language.toLowerCase()] }}</option>
                    <option *ngFor="let view of locationslist; index as i" value="{{view.name}}">
                        {{view.name}}
                    </option>
                </select>
                <p class="error-p" *ngIf="locationsflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
        </div>
        <div class="row py-sm-2">
            <div class="col-md-6 col-12">
                <input class="form-control" [ngClass]="phoneflag ? 'ng-invalid' : ''" type="text" placeholder="{{ translations[3][language.toLowerCase()] }}" name="telefono" [(ngModel)]="phone">
                <p class="error-p" *ngIf="phoneflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
            <div class="col-md-6 col-12 contact-us">
                <select class="form-control" [ngClass]="profilesflag ? 'ng-invalid' : ''" [(ngModel)]="profiles" (change)="typeprofile()">
                    <option hidden [ngValue]="null" [disabled]="true" selected>{{ translations[8][language.toLowerCase()] }}</option>
                    <option *ngFor="let view of profileslist; index as i" value="{{view.id}}">
                        {{view.name}}
                    </option>
                </select>
                <p class="error-p" *ngIf="profilesflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
        </div>
        <div class="row pt-sm-2">
            <div class="col-12">
                <textarea class="form-control" [ngClass]="textbodyflag ? 'ng-invalid' : ''" placeholder="{{ translations[4][language.toLowerCase()] }}" style="height: 156px; resize: none;" [(ngModel)]="textbody"></textarea>
                <p class="error-p" *ngIf="textbodyflag">{{ translations[10][language.toLowerCase()] }}</p>
            </div>
        </div>
        <div class="row">
            <div class="col-12" style="text-align: center;">
                <button (click)="mailto()" class="btn btn-contact">{{ translations[14][language.toLowerCase()] }}</button>
            </div>
        </div>
        <div class="row" *ngIf="infosuccess">
            <div class="alert alert-success" role="alert">
                {{ translations[11][language.toLowerCase()] }}
            </div>
        </div>
        <div class="d-block d-sm-none" style="margin-top: 33px;">
            <div class="col-12">
                <div class="row">
                    <div class="col-12">
                        <br>
                        <div class="text-say-hello" style="margin-bottom: 8px;"><a href="tel:+528181040389">t. +52 81 8104 0389</a></div>
                    </div>
                    <div class="col-12">
                        <br>
                        <div class="text-say-hello" style="margin-bottom: 8px;"><a href="mailto:contacto@playful.mx">contacto@playful.mx</a></div>
                    </div>
                </div>
                <!-- <div class="row">
                    <div class="col-12 subtitle-say-hello">
                        MONTERREY, N.L HQ
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-say-hello">
                        Av. Revolución No. 643 Local 12 <br>Col. Jardín Español, Monterrey, <br>Nuevo León <br>CP.: 64820
                    </div>
                </div>
                <div class="row" style="margin-top: 26px;">
                    <div class="col-12 subtitle-say-hello">
                        CDMX
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-say-hello">
                        Coahuila 14 int. 303 <br>Col. Roma Norte <br>Ciudad de México <br>CP.: 04380
                    </div>
                </div>
                <div class="row">
                    <div class="col-12">
                        <br>
                        <div class="tlf-say-hello" style="margin-bottom: 8px;"><a href="mailto:contacto@playful.mx">contacto@playful.mx</a></div>
                    </div>
                </div> -->
            </div>
        </div>
    </div>
</div>