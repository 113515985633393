import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Router } from '@angular/router';

import { EmailDto } from '../../dto/email-dto';

@Injectable({
  providedIn: 'root'
})
export class ContactusService {
  uri = environment.apiuri + 'sendemail';

  constructor(
    private httpClient: HttpClient,
    public router: Router
  ) { }

  async sendmail(emailDto : EmailDto) {
    const response = await this.httpClient.post(`${this.uri}`, emailDto, {headers: new HttpHeaders().set('Content-Type', 'application/json')}).toPromise();
    return response;
  }
}
