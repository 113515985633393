<div class="row m-0 p-sm-5 container-footer">
    <div class="col-lg-1 col-3 img-logo-footer">
        <img src="./assets/icons/logo-footer-pf.svg" alt="">
    </div>
    <div class="col-lg-11 col-9">
        <div class="container-footer-date">
            <div class="vertical-center">
                <p class="py-2">PLAYFUL.MX ©{{currentYear}} / Todos los derechos reservados.</p>
            </div>
        </div>
    </div>
</div>