import { Component, OnInit } from '@angular/core';

import { ProfilesDto } from '../dto/profiles-dto';
import { ProfilesService } from './profiles.service';

@Component({
  selector: 'app-profiles',
  templateUrl: './profiles.component.html',
  styleUrls: ['./profiles.component.css']
})
export class ProfilesComponent implements OnInit {

  viewtable: any = [];

  id: string = "";
  name: string = "";
  emails:string = "";

  constructor(
    private profilesService: ProfilesService
  ) { }

  ngOnInit(): void {
    this.viewalltable();
  }

  async addtext() {
    if (this.name != "" && this.emails != "") {
      let profilesDto = new ProfilesDto;
      profilesDto.name = this.name;
      profilesDto.emails = this.emails;
      profilesDto.active = "1";
      let answer = "";
      if (this.id == "") {
        answer = await this.profilesService.post(profilesDto);
      } else {
        profilesDto.id = this.id;
        answer = await this.profilesService.put(profilesDto);
      }
      if (answer['status'] == "200") {
        alert("Se a cargado con exito");
        this.viewalltable();
      } else {
        alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
      }
      this.clear();
    } else {
      alert("Favor de llenar todo el formulario");
    }
  }

  async clear() {
    this.name = "";
    this.emails = "";
    this.id = "";
  }

  async viewalltable() {
    let answer = await this.profilesService.getall();
    if (answer['status'] == "200") {
      this.viewtable = answer['body'];
    }
  }

  async active(id) {
    let profilesDto = new ProfilesDto;
    profilesDto.id = id;
    profilesDto.active = "1";
    var answer = await this.profilesService.put(profilesDto);

    if (answer['status'] == "200") {
      alert("Se a cargado con exito");
      this.viewalltable();
    } else {
      alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
    }
  }

  async desactive(id) {
    let profilesDto = new ProfilesDto;
    profilesDto.id = id;
    profilesDto.active = "0";
    var answer = await this.profilesService.put(profilesDto);

    if (answer['status'] == "200") {
      alert("Se a cargado con exito");
      this.viewalltable();
    } else {
      alert("Se ha presentado un problema, favor de contactar al área de desarrolo");
    }
  }

  async edit(id, name, emails) {
    this.id = id;
    this.name = name;
    this.emails = emails;
  }

}
