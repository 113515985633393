<div class="top-portafolio">
  <div class="row base-1 m-0">
    <div class="col-12 p-0">
      <div class="row base-2 m-0">
        <div class="col-12 p-0">
          <div class="row m-0">
            <div class="col-12 p-0 container-title-envisioning">
              <div class="title-envisioning max-width-global max-margin-global">
                {{ translations[0][language.toLowerCase()] }}
              </div>
              <div class="img-banner-envisioning">
                <img src="./assets/images/img-envisioning-banner.svg" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 p-0">
          <div class="img-arrow-down-blue" (click)="scrollToCase(success)">
            <img src="./assets/images/arrow-down-blue.svg" alt="" />
          </div>
        </div>
      </div>
      <div class="row m-0 container-cases-success" #success>
        <div class="col-12 p-0">
          <div class="subtitle">
            {{ translations[1][language.toLowerCase()] }}
          </div>
          <div class="title">
            {{ translations[2][language.toLowerCase()] }}
            <span>{{ translations[3][language.toLowerCase()] }}</span>
          </div>
        </div>
      </div>
      <div class="container-video">
        <div class="row m-0 subcontainer">
          <div class="col-6 p-0 img-vid-1 d-none d-lg-block">
            <div class="container-img-video">
              <img src="./assets/images/vid-exp-retail.png" alt="" />
              <button
                class="btn video-btn"
                (click)="openModalVideo('3ybxihQpbKU')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[4][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 img-vid-1 d-block d-lg-none">
            <div class="container-img-video">
              <img src="./assets/images/vid-exp-retail-mb.png" alt="" />
              <button
                class="btn video-btn"
                (click)="openModalVideo('3ybxihQpbKU')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[4][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-6 p-0 img-vid-2 d-none d-lg-block">
            <div class="container-img-video">
              <img src="./assets/images/vid-bank-future.png" alt="" />
              <button
                class="btn video-btn"
                (click)="openModalVideo('ShB4kXoZ1HI')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[6][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-12 col-md-6 p-0 img-vid-2 d-block d-lg-none">
            <div class="container-img-video">
              <img src="./assets/images/vid-bank-future-mb.png" alt="" />
              <button
                class="btn video-btn"
                (click)="openModalVideo('ShB4kXoZ1HI')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[6][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-12 img-vid-3 p-0 d-none d-lg-block">
            <div class="container-img-video">
              <img src="./assets/images/vid-onboarding.png" alt="" />
              <button
                class="btn video-btn"
                (click)="openModalVideo('Sk-u1r9rFl0')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[8][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-12 img-vid-3 p-0 d-block d-lg-none">
            <div class="container-img-video">
              <img
                class="d-block d-md-none"
                src="./assets/images/vid-onaboarding-mb.png"
                alt=""
              />
              <img
                class="d-md-block d-none"
                src="./assets/images/vid-onboarding-tablet.png"
                alt=""
              />
              <button
                class="btn video-btn"
                (click)="openModalVideo('Sk-u1r9rFl0')"
                data-toggle="modal"
                data-target="#modal-video"
              >
                <img src="./assets/icons/play.svg" alt="" />
              </button>
              <span>{{ translations[8][language.toLowerCase()] }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="container-wow">
        <div class="row m-0 max-width-global max-margin-global">
          <div class="col-lg-4 col-12 p-0">
            <div class="title-wow">
              {{ translations[9][language.toLowerCase()] }} <br />
              <span>{{ translations[10][language.toLowerCase()] }}</span>
            </div>
          </div>
          <div class="col-lg-8 col-12 p-0">
            <div class="row container-img-wow">
              <div class="col-6 col-md-3" *ngFor="let wow of array_wow">
                <div class="img-wow">
                  <img [src]="wow.url_logo" [alt]="wow.name" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="testimony max-width-global max-margin-global">
        <div
          id="carousel-testimony-wow"
          class="carousel slide"
          data-interval="false"
        >
          <ol class="carousel-indicators">
            <li
              data-target="#carousel-testimony-wow"
              data-slide-to="0"
              class="active"
            ></li>
            <li data-target="#carousel-testimony-wow" data-slide-to="1"></li>
            <li data-target="#carousel-testimony-wow" data-slide-to="2"></li>
            <li data-target="#carousel-testimony-wow" data-slide-to="3"></li>
          </ol>
          <div class="carousel-inner">
            <div
              *ngFor="let t of array_testimony; let i = index"
              class="carousel-item"
              [class]="i === 0 ? 'active' : 'inactive'"
            >
              <div class="comment">
                <img src="./assets/icons/wow/comilla-blue.svg" alt="" />
                <span *ngIf="language == 'ES'">
                  {{ t.comentario }}
                </span>
                <span *ngIf="language == 'EN'">
                  {{ t.comentario_en }}
                </span>
              </div>
              <div class="info d-flex">
                <span *ngIf="language == 'ES'">
                  {{ t.puesto }}
                </span>
                <span *ngIf="language == 'EN'">
                  {{ t.puesto_en }}
                </span>
                <hr />
                <img class="d-md-block d-none" [src]="t.logo" alt="" />
              </div>
              <div class="d-block d-md-none img-info-logo">
                <img [src]="t.logo" alt="" />
              </div>
            </div>
          </div>
          <a
            class="carousel-control-prev"
            href="#carousel-testimony-wow"
            role="button"
            data-slide="prev"
          >
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="sr-only">Previous</span>
          </a>
          <a
            class="carousel-control-next"
            href="#carousel-testimony-wow"
            role="button"
            data-slide="next"
          >
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="sr-only">Next</span>
          </a>
        </div>
      </div>
    </div>
  </div>
  <div
    class="modal fullscreen-modal fade"
    id="modal-video"
    data-backdrop="static"
    data-keyboard="false"
    (clickOutside)="close_iframe()"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <button
            type="button"
            class="close"
            (click)="close_iframe()"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <!-- 16:9 aspect ratio -->
          <div class="embed-responsive embed-responsive-16by9">
            <iframe
              class="embed-responsive-item"
              src=""
              id="video"
              allowscriptaccess="always"
              allow="autoplay"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row m-0">
  <div class="col-12 p-0" style="max-width: 1280px; margin: 0 auto">
    <app-contactus></app-contactus>
  </div>
</div>
