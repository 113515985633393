<div class="row">
    <div class="col-12 p-0">
        <div class="row form-contact">
            <div class="col-lg-6 col-12">
                <div class="row py-5">
                    <div class="col-12 title-say-hello">
                        Perfiles
                    </div>
                </div>

            </div>
            <div class="col-lg-6 col-12 py-sm-5 box-form-contact">
                <div class="row py-sm-2">
                    <div class="col-md-6 col-12">
                        <input class="form-control" type="text" placeholder="Nombre" name="name" [(ngModel)]="name">
                    </div>
                    <div class="col-md-6 col-12">
                        <input class="form-control" type="text" placeholder="Correo" name="emails" [(ngModel)]="emails">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12" style="text-align: center;">
                        <button class="btn btn-contact" (click)="addtext()">Agregar</button>
                    </div>
                    <div class="col-12" style="text-align: center;">
                        <button class="btn btn-contact" (click)="clear()">Limpiar</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="col-12 p-0" style="margin-left: 5%;">
        <table class="table">
            <thead>
                <tr>
                    <th scope="col">Nombre</th>
                    <th scope="col">E-mail</th>
                    <th scope="col">Editar</th>
                    <th scope="col">&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let view of viewtable; index as i">
                    <th scope="row">{{view.name}}</th>
                    <th scope="row">{{view.emails}}</th>
                    <td><button class="btn btn-success" (click)="edit(view.id, view.name, view.emails)">Editar</button></td>
                    <td><button class="btn btn-danger" *ngIf="view.active == 1" (click)="desactive(view.id)">Desactivar</button></td>
                    <td><button class="btn btn-info" *ngIf="view.active != 1" (click)="active(view.id)">Activar</button></td>
                </tr>
            </tbody>
        </table>
    </div>
</div>