import { Component } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'portalplayful';
  location: Location;
  loading = true;

  constructor(private meta_title: Title,
              private meta_description: Meta){}

  ngOnInit() {

    var language = navigator.language;

    //COMENTAR ESTE BLOQUE IF PARA LEVANTAR EN DESARROLLO
    if (location.protocol === 'http:') {
      window.location.href = location.href.replace('http', 'https');
    }
    //FIN BLOQUE IF COMENTADO PARA DESARROLLO
    
    this.meta_title.setTitle("Playful | Business innovation");

    var content_description = "";
    
    if(language.includes("es")){
      content_description = `Consultora de Innovación de Negocios. 
      Diseñamos soluciones estratégicas para optimizar tus servicios y productos digitales.`
    }else{
      content_description = `Business Innovation Consulting. 
      We design strategic solutions to optimize your digital services and products.`
    }
    
    this.meta_description.addTag({name: "description", content: content_description})
    this.meta_description.addTag({name: "keywords", content: "Playful playful UX RPA innovation"})
    
    setTimeout(() => {
      this.loading = false;
    }, 0);
    
    if(localStorage.getItem("lang") == null){
      if(language.includes("es")){
        localStorage.setItem("lang","ES");
      }else{
        localStorage.setItem("lang","EN");
      }
    }
  }
}
