import { Component, OnInit, HostListener } from '@angular/core';
import { TranslationsService } from '../../translations/translations.service';
import { Event, NavigationError, NavigationStart, Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit {

  public servicesLink = "/servicios";
  public briefcaseLink = "/portafolio";
  public usLink = "/nosotros";

  public actual_page:string = "/home";

  public img_to_show:string = "logo-nav-pf";
  public img_to_show_mb:string = "logo-nav-pf-mob";
  public color_navbar:string = "#0E1116";

  public langSelected: string = "ES";
  public translations = [];

  public social_networks = [
    {href: "https://www.facebook.com/playfulmx/", img_src: "./assets/icons/social-networks/FACEBOOK.svg"},
    {href: "https://twitter.com/playfulmx", img_src: "./assets/icons/social-networks/TWITTER.svg"},
    {href: "https://www.instagram.com/playfulgram/", img_src: "./assets/icons/social-networks/INSTAGRAM.svg"},
    // {href: "#behance", img_src: "./assets/icons/social-networks/BEHANCE.svg"},
    {href: "https://www.linkedin.com/company/playful-interactive/", img_src: "./assets/icons/social-networks/LINKEDIN.svg"}
  ]

  constructor(private translationsService : TranslationsService,
    private router: Router) { 
        this.router.events.subscribe((event: Event) => {
          if (event instanceof NavigationStart) {
            this.actual_page = event.url;
            if(event.url === this.briefcaseLink){
              this.img_to_show = "logo-nav-pf-red";
              this.img_to_show_mb = "logo-nav-pf-red-mob";
              this.color_navbar = "#FF2C50";
            }else{
              this.img_to_show = "logo-nav-pf";
              this.img_to_show_mb = "logo-nav-pf-mob";
              if(event.url === this.servicesLink){
                this.color_navbar = "#0000FF";
              }else{
                this.color_navbar = "#0E1116";
              }
            }
        }

        if (event instanceof NavigationError) {
            // Handle error
            console.error(event.error);
        }

        if (event instanceof NavigationEnd) {
            //do something on end activity
        }
        });
    }

  ngOnInit(): void {
    if(localStorage.getItem("lang") != null){
      this.langSelected = localStorage.getItem("lang");
    }else{
      this.langSelected = "ES";
    }
    this.view();
  }

  changeLang(lang:string){
      this.langSelected = lang.toUpperCase();
      localStorage.setItem("lang", this.langSelected);
      window.scrollTo(0, 0);
      window.location.reload();
  }

  view(){
    this.translationsService.getone("6").subscribe(
      res => {
        // console.log(res);
        this.translations = res['body'];
      },
      err =>{
        console.log(err);
      }
    )
  }
  // async view(){
  //   this.translations = [];
  //   var answer = await this.translationsService.getone("6");
  //   console.log(answer);
    
  //   if(answer['status'] == "200"){
  //     this.translations = answer['body']; 
  //   }
  // }

  @HostListener("window:scroll", ["$event"])
  onWindowScroll() {
    var navbar_pf = document.getElementById('nav-pf');
    if (window.scrollY >= 100 ) {
      navbar_pf.classList.add("navbar-scroll");
    } else {
      navbar_pf.classList.remove("navbar-scroll");
    }
  }

}
